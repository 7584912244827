import React, { useState } from "react"
import styled from "styled-components"
import { graphql, Link, useStaticQuery } from "gatsby"
import {
  Accordions,
  Accordionsitems,
  AccordionsContent,
  AccordionsContentinner,
  AccordionsTitle,
} from "../Accordion"


const ListCityState = styled.div`
  display:flex;
  flex-wrap:wrap;
  margin:0 -5px;
 
`
const ListItemCityState = styled.div`
  padding:5px 5px 5px 15px;
  margin:0;
  position:relative;
  &:before{
    content: '';
    position: absolute;
    left: 0;
    width: 6px;
    height: 6px;
    background-color: #516c7c;
    border-radius: 50%;
    top: 50%;
    transform: translateY(-50%);
  }
  & a {
    display:inline-block;
    font-size:16px;
    line-height:26px;
    color: #062c44;
    &:hover, &:focus {
      color: #ed602b;
    }
  }
  flex: 0 0 50%;
  max-width: 50%;

  @media (min-width: 576px) {
    flex: 0 0 33.33333%;
    max-width: 33.33333%;
  }

  @media (min-width: 992px) {
    flex: 0 0 25%;
    max-width: 25%;
  }

  @media (min-width: 1200px) {
    flex: 0 0 20%;
    max-width: 20%;
  }
  
`


const StateAccordion = () => {
  const [activeFaq, setActiveFaq] = useState(0)
  const handleAccodion = key => {
    if (activeFaq === key) {
      return setActiveFaq(null)
    }
    setActiveFaq(key)
  }

  const data = useStaticQuery(graphql`
    query {
      allContentfulStateLanding {
        edges {
          node {
            id
            stateName
            url
          }
        }
      }
    }
  `)

  const states = data.allContentfulStateLanding.edges

  return (
    <Accordions className="accordions">
      <Accordionsitems
        className={`accordions-items ${activeFaq === 1 ? "active" : ""}`}
      >
        <AccordionsTitle
          className="accordions-title"
          onClick={() => handleAccodion(1)}
        >
          Our Service Areas<span></span>
        </AccordionsTitle>
        <AccordionsContent className="accordions-content">
          <AccordionsContentinner>
            <ListCityState>
              {states.map((state, i) => (
              <ListItemCityState>
                <Link to={state.node.url} key={i}>
                  {state.node.stateName}
                </Link>
              </ListItemCityState>
              ))}
            </ListCityState>
          </AccordionsContentinner>
        </AccordionsContent>
      </Accordionsitems>
    </Accordions>
  )
}

export default StateAccordion
